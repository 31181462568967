/******************************
 * Hack-ish way of using twitter bootstrap plugins with namespaced
 * CSS classes.
******************************/
// jQuery required.
window.jQuery = require('jquery');

// These are the customized Javascripts for prefixed CSS.
// NOTE THE `./` !  That's what designates local.
require('./bootstrap/modal.js');
require('./bootstrap/collapse.js');
require('./bootstrap/tab.js');

// These are the default Bootstrap Javascript.
// require('bootstrap-sass/assets/javascripts/bootstrap/affix.js');
// require('bootstrap-sass/assets/javascripts/bootstrap/alert.js');
// require('bootstrap-sass/assets/javascripts/bootstrap/button.js');
// require('bootstrap-sass/assets/javascripts/bootstrap/carousel.js');
// require('bootstrap-sass/assets/javascripts/bootstrap/dropdown.js');
// require('bootstrap-sass/assets/javascripts/bootstrap/scrollspy.js');
// require('bootstrap-sass/assets/javascripts/bootstrap/tooltip.js');
// require('bootstrap-sass/assets/javascripts/bootstrap/popover.js');
require('bootstrap-sass/assets/javascripts/bootstrap/transition.js');