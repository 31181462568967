// ./controllers/flatpickr_controller.js
import "flatpickr/dist/themes/material_blue.css";
import "flatpickr/dist/ie.css";

import jQuery from "jquery";
import flatpickr from "flatpickr";
import { Spanish } from 'flatpickr/dist/l10n/es.js'
import { French } from 'flatpickr/dist/l10n/fr.js'

const SELECTOR = '[data-controller="flatpickr"]';

jQuery((jQuery) => {
  jQuery(SELECTOR).map((i,obj) => {
    let $obj = jQuery(obj);
    flatpickr(obj, {});
  });
});