// Polyfills
require("classlist-polyfill");
// Polmaker Specific CSS
require("./bootstrap.js");

// jQuery controllers.
require("./jquery/flatpickr_controller.js");
require("./jquery/refreshes_controller.js");
require("./jquery/remote_content_controller.js");

// For some reason Stimulus is loading, but not actually connecting to
// DOM elements.  Not sure why!
// import "@stimulus/polyfills"; // Stimulus - Not viable with IE.
// import { Application } from "stimulus";
// import { definitionsFromContext } from "stimulus/webpack-helpers";

// const application = Application.start();
// const context = require.context("./controllers", true, /\.js$/);
// application.load(definitionsFromContext(context));
