// ./controllers/vehicle_controller.js
import jQuery from "jquery";

const SELECTOR = '[data-pm-refreshes]';

jQuery((jQuery) => {
  // Whenever the dropdowns change...
  jQuery(document).on('change.pm.refreshes', SELECTOR, (event) => {
    let $obj = jQuery(event.target);
    let $form = $obj.closest('form');
    let target_selector = $obj.data('pm-refreshes');
    let $targets = jQuery(target_selector);
    if ($targets.length > 0) {
      let data = $form.serialize();
      // Disable the form while it's replacement is loaded.
      jQuery('*:input', $targets).attr('disabled','disabled');
      $targets.each((i,target) => {
        let $target = jQuery(target);
        let url = `${$form.attr('action')} #${$target.attr('id')}`
        $target.load(url, data, () => {
          jQuery('*:input', $target).trigger('load');
          if ($target.children().length > 0) {
            $target.removeClass('pm--hidden');
          }
        });
      });
    }
  });
  // jQuery(SELECTOR).trigger('change');
});