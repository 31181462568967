// ./controllers/vehicle_controller.js
import jQuery from "jquery";

const ATTR = "data-pm-remote";
const SELECTOR = `[${ATTR}]`;

jQuery((jQuery) => {
	jQuery(SELECTOR).map((i, obj) => {
		let $obj = jQuery(obj);
		let url = $obj.attr(ATTR);
		fetch(url)
			.then((resp) => resp.text())
			.then((body) => {
				obj.innerHTML = body;
			})
			.catch((error) => {
				console.error(`Error loading "${url}":  `, error);
			});
	});
});
